import React from 'react';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import logo from '../assets/images/flow-logo.svg';
import Social from './Social';
import { getFlowButtonHandler } from './Dialog';

const Footer = () => {
  const { t, i18n } = useTranslation('footer');
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__block">
          <a
            href="#"
            rel="noopener noreferrer"
            target="_self"
            className="logo footer__logo"
          >
            <img src={logo} alt="flow" className="logo__icon" />
          </a>
          <nav className="footer__nav">
            <a
              href="https://work.flowyour.money"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link u-color-primary"
              style={{fontWeight: 900}}
            >
              {t('navLinks.hiring', 'Hiring')}
            </a>
            <Link to="/blog" language={i18n.language} className="footer__link u-color-primary">
              {t('navLinks.0', 'Articles')}
            </Link>
            <a
              href="/guides"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link u-color-primary"
            >
              {t('navLinks.1', 'Guides')}
            </a>
            <Link to="/dragons-den" language={i18n.language} className="footer__link u-color-primary">
              {t('navLinks.2', 'Dragons’ Den')}
            </Link>
            <a
              href="/press-kit"
              rel="noopener noreferrer"
              target="_blank"
              className="footer__link u-color-primary"
            >
              {t('navLinks.4', 'Press Kit')}
            </a>
          </nav>
          <button
            className="btn btn--primary btn--sm footer__btn"
            onClick={() => getFlowButtonHandler()}
          >
            {t('buttonTitle', 'Get the app')}
          </button>
        </div>
        <div className="footer__block">
          <div className="footer__copyright u-color-gray">
            {t('copyright', {
              year: new Date().getFullYear(),
            })}
          </div>
          {/* Social */}
          <Social className="footer__social" />
          <div className="footer__policies">
            <Link className="footer__policy u-color-gray" language={i18n.language} to="/privacy-policy">
              {t('policies.0', 'Privacy Policy')}
            </Link>
            <Link className="footer__policy u-color-gray" language={i18n.language} to="/terms-of-use">
              {t('policies.1', 'Terms of Use')}
            </Link>
            <Link className="footer__policy u-color-gray" language={i18n.language} to="/cookies">
              {t('policies.2', 'Cookies')}
            </Link>
            <Link className="footer__policy u-color-gray" language={i18n.language} to="/responsable-disclosure-policy">
              {t('policies.3', 'Report Vulnerability')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
