import React, { useContext, useRef, useState } from 'react';
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next';
import cx from 'classnames';
import enFlag from '../assets/images/icons/gb-flag.svg';
import nlFlag from '../assets/images/icons/nl-flag.svg';
import { useOnClickOutside } from '../hooks/useOnClickOutside';

const usedLanguages = {
  en: enFlag,
  nl: nlFlag,
};

/** For passing translated URI paths, excluding language prefix. A map of: Language -> Path */
export const LanguageSwitchContext = React.createContext<{
  languageToPath?: Record<string, string>;
}>({});

const LanguageSwitch = () => {
  const { i18n } = useTranslation('header');
  const { languages, originalPath } = useI18next();
  const language = i18n.language as keyof typeof usedLanguages;
  const [isActive, setActive] = useState(false);
  const { languageToPath } = useContext(LanguageSwitchContext);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setActive(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className="languages" ref={ref}>
      <div className="languages__container">
        <button
          onClick={() => setActive(!isActive)}
          className={cx('languages__selected', {
            'languages__selected--open': isActive,
          })}
        >
          <img src={usedLanguages[language]} alt="flag" />
        </button>
        <ul
          className={cx('languages__list', {
            'languages__list--open': isActive,
          })}
        >
          {languages
            .filter((lng) => lng !== i18n.language)
            .map((lng) => (
              <Link
                key={lng}
                to={(languageToPath && languageToPath[lng]) || originalPath}
                language={lng}
                className="languages__item"
              >
                <img src={lng === 'nl' ? nlFlag : enFlag} alt={lng} />
              </Link>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default LanguageSwitch;
