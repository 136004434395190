import React, { useEffect, useState } from 'react';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import LanguageSwitch from './LanguageSwitch';
import { getFlowButtonHandler } from './Dialog';
import cx from 'classnames';
import logo from '../assets/images/flow-logo.svg';
import { useLocation } from '@reach/router';

const Header = () => {
  const { t, i18n } = useTranslation('header');
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [path, setPath] = useState('');
  const location = useLocation();
  const hiring = false;
  const announcement = true;

  useEffect(() => {
    setPath(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = `${isOpen ? 'hidden' : 'unset'}`;
    const delayMobile = setTimeout(() => setIsMobile(false), 800);
    isOpen ? setIsMobile(true) : delayMobile;
    return () => clearTimeout(delayMobile);
  }, [isOpen]);

  return (
    <header className={`header ${announcement || hiring ? 'announcement' : ''}`}>
      {!announcement ? null : (
        <div className="announcement-banner">
          <div className="content">
            <a href="https://withflow.com" target="_blank" className="">
              {t('menu.announcement_banner')}
              &nbsp;<span className="url">withflow.com</span>&nbsp;&rarr;
            </a>
          </div>
        </div>
      )}
      {!hiring ? null : (
        <div className="announcement-banner">
          <div className="content">
            <a href="https://work.flowyour.money" target="_blank" className="">
              {t('menu.hiring_banner')}
            </a>
          </div>
        </div>
      )}
      <div className="container">
        <Link to="/" language={i18n.language} className="logo header__logo">
          <img src={logo} alt="flow" className="logo__icon" />
        </Link>
        <nav
          className={cx('nav header__nav', {
            'nav--mobile': isMobile,
            'nav--mobile--open': isOpen,
          })}
        >
          <Link
            to="/pricing"
            language={i18n.language}
            className={cx('nav__link', {
              'nav__link--active': path === 'pricing',
            })}
          >
            {t('menu.pricing', 'Pricing')}
          </Link>
          <Link
            to="/banks"
            language={i18n.language}
            className={cx('nav__link', {
              'nav__link--active': path === 'banks',
            })}
          >
            {t('menu.banks', 'Banks')}
          </Link>
          <Link
            to="/blog"
            language={i18n.language}
            className={cx('nav__link', {
              'nav__link--active': path === 'blog',
            })}
          >
            {t('menu.blog', 'Blog')}
          </Link>
          <a
            href="/guides"
            target="_blank"
            rel="noopener noreferrer"
            className={cx('nav__link', {
              'nav__link--active': path === 'guides',
            })}
          >
            {t('menu.guides', 'Guides')}
          </a>
          <a href="https://work.flowyour.money" target="_blank" className="nav__link">
            {t('navLinks.hiring', 'Hiring')}
          </a>
          <button
            className="btn btn--primary btn--sm nav__button"
            onClick={() => getFlowButtonHandler()}
          >
            {t('buttonTitle', 'Get the app')}
          </button>
        </nav>
        <LanguageSwitch />
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={cx('b-menu', {
            'b-menu--open': isOpen,
          })}
        >
          <div className="b-menu__bun b-menu__bun--top"></div>
          <div className="b-menu__bun b-menu__bun--mid"></div>
          <div className="b-menu__bun b-menu__bun--bot"></div>
        </button>
      </div>
    </header>
  );
};

export default Header;
