import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Dialog from './Dialog';
import { MailButton } from './MailButton';

interface LayoutProps {
  children: React.ReactNode;
}

// Note, the gist chat messenger embed is manage through google tag manager.

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Dialog />
      <MailButton />
    </>
  );
};

export default Layout;
